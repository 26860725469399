@import "./assets/styles/colors";

html {
  padding: 0;
  margin: 0;
  font-family: "FiraGO", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

.pointer {
  cursor: pointer;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

header {
  position: sticky;
  top: 0;
  z-index: 3;
  transition: all 0.3s linear;
}

.nav-wrapper {
  background-color: $white;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 4;
  position: relative;
}

.drawer {
  box-shadow: 0 0 0 !important;
  background-color: transparent !important;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 64px;
  z-index: 3 !important;
  height: 100vh !important;
}

.navigation-drawer {
  gap: 8px;
  background-color: transparent !important;
  display: flex;
  flex-direction: column;
  padding-top: 128px;
}

.drawer-background {
  height: 100%;
  background-color: #f2f4f5;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 16px;

  &-navigation {
    height: auto;

    & div:hover {
      color: $main;
    }
  }
}

.drawer-item {
  max-height: 99px;
  flex: 1;
  display: flex;
  background-color: #fff;
  border-radius: 14px;
  cursor: pointer;
  &-center {
    justify-content: center;
    align-items: center;
    max-height: 46px;
  }

  &-left {
    justify-content: flex-start;
    align-items: flex-end;
    padding: 16px;
  }
}

.EZDrawer__overlay {
  background-color: #000 !important;
  z-index: 2 !important;
  height: calc(120vh + 64px) !important;
}

.nav {
  align-items: center;
  align-self: center;
  max-width: 1410px;
  width: 100%;
  margin: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 86px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    height: 64px;
    margin: 0 32px;
  }
}

.nav-link {
  &.active,
  &:hover {
    color: $main;
  }
}

.nav-secondary-wrapper {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  height: 86px;
  width: 100%;
  background: rgba(255, 255, 255, 0.18);
  backdrop-filter: blur(30px);
  --webkit-backdrop-filter: blur(30px);

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    height: 64px;
  }
}

.nav-secondary-background {
  height: 172px;
  width: 100%;
  background-color: $white;
  opacity: 0.7;
  z-index: -1;
  position: fixed;
  top: 0;
  transition: all 0.3s linear;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    height: 128px;
  }
}

footer {
  padding: 128px 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: $main;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    padding: 32px;
    gap: 24px;
  }
}

.footer-logo {
  border: 0.5px solid $grey;
  border-radius: 30px;
}

.mw415 {
  max-width: 415px;
}

.mw1400 {
  max-width: 1400px;
}

.mw185 {
  max-width: 185px;
}

.dowload-app-wrapper {
  position: fixed;
  z-index: 100;
  bottom: -130px;
  left: 50%;
  transition: all 0.5s linear;

  &.active {
    opacity: 1;
    transform: translateY(-150px);
  }
}
