.mb4 {
  margin-bottom: 4px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb16 {
  margin-bottom: 16px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    margin-bottom: 8px;
  }
}

.mb32 {
  margin-bottom: 32px;
}

.mb64 {
  margin-bottom: 64px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    margin-bottom: 32px;
  }
}

.mb128 {
  margin-bottom: 128px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    margin-bottom: 64px;
  }
}

.gap8 {
  gap: 8px;
}

.gap16 {
  gap: 16px;
}

.gap32 {
  gap: 32px;
}

.gap64 {
  gap: 64px;

  @media (max-width: 1360px) {
    gap: 32px;
  }
}

.gap128 {
  gap: 128px;
}

.mh32 {
  margin-left: 32px;
  margin-right: 32px;
}

.mh64 {
  margin-left: 96px;
  margin-right: 96px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    margin-left: 48px;
    margin-right: 48px;
  }
}

.mh96 {
  margin-left: 96px;
  margin-right: 96px;
}

.p32 {
  padding: 32px;
}
