@import "../../assets/styles/colors";

.content {
  margin-top: 64px;
}

.first-section {
  background-color: $white;
  padding: 256px 64px 128px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    padding: 32px;
  }
}

.canvas-container {
  display: block;
  justify-content: center;
  align-items: center;
}

canvas {
  // right: 0;
  z-index: -1;
}

.coins {
  max-width: -webkit-fill-available;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    width: 100%;
  }
}

.right {
  position: relative;
  display: flex;
  height: 570px;

  @media (max-width: 1200px) {
    flex: 1;
    height: 800px;
    align-items: center;
    margin-top: 192px;
  }

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    display: flex;
    justify-content: center;
    margin-top: 92px;
    margin-bottom: 92px;
    left: -32px;
  }

  & .images {
    position: absolute;
    transition: opacity 0.3s linear;

    & img:first-of-type {
      position: relative;
      top: -95px;
      right: -155px;
      z-index: 1;

      @media (max-width: 640px),
        (orientation: landscape) and (max-width: 1023px) {
        position: absolute;
        top: -20px;
        right: 90px;
        width: 200px !important;
      }
    }

    & img:last-of-type {
      position: relative;
      bottom: -90px;
      left: 15px;
      z-index: 1;
      width: 200px;

      @media (max-width: 640px),
        (orientation: landscape) and (max-width: 1023px) {
        position: absolute;
        bottom: -25px;
        left: 55px;
        width: 90px !important;
      }
    }
  }
}

.qr-wrapper {
  display: flex;
  flex-direction: row;
  height: 128px;
  align-items: center;
  border: 1px solid $grey;
  border-radius: 26px;
  padding: 16px 16px 16px 0;
  width: 284px;
  justify-content: space-between;
}

.qr-wrapper-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 33px;
  padding: 12px 49px;
  justify-content: space-between;
  background-color: $white;
  text-align: center;
  border: 1px solid $grey;
}

.desc {
  position: absolute;
  @media (max-width: 1400px) {
    position: relative;
  }
}

.arrow {
  width: 39px;
  height: 39px;
  border-radius: 39px;
  background-color: $grey;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &-right {
    transform: rotate(180deg);
  }
}

.arrow-buttons {
  align-items: flex-end;
  margin: 0 128px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 32px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    margin: 0 64px;
  }
}

.card-wrapper {
  width: 400px;
  display: flex;
  // flex: 1;
  flex-direction: column;
  padding: 32px;
  border-radius: 30px;
  overflow: hidden;
  height: 731.5px;
  justify-content: space-between;
  background-position: center;
  background-size: contain;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    width: 245px;
    height: 448px;
    padding: 16px;
  }
}

.small-icon-wrapper {
  width: 64px;
  height: 64px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    width: 36px;
    height: 36px;
    border-radius: 7px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.footer-icon {
  width: 19px;
  height: 19px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    width: 14px;
    height: 14px;
  }
}

.slider {
  padding-left: 64px !important;
  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    padding-left: 32px !important;
  }

  // & > li {
  //   @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
  //   }
  // }
  // & > li:first-of-type {
  // }
}

.carousel-container {
}

.third-section {
  background-color: $white;
  padding: 128px 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    padding: 32px 16px;
  }
}

.block {
  max-width: 816px;
  border-radius: 30px;
  background: $main;
  display: flex;
  flex: 1;
  flex-basis: 100%;
  padding: 64px;
  flex-direction: column;
  align-items: center;

  &:first-of-type {
    background: linear-gradient(#000 0%, #00635f 100%);
  }

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    min-width: 300px;
    padding: 16px;
  }
}

.block-description {
  max-width: 456px;
}

.block-line {
  width: 134px;
  height: 4px;
  border-radius: 2px;
  background: $white;
}

.section4 {
  padding: 128px 64px 0;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    padding: 64px 32px 0;
  }
}

.check-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  border-bottom: 1px solid $grey;
  position: relative;
  &::after {
    content: "";
    display: block;
    width: 32px;
    height: 1px;
    position: absolute;
    right: -32px;
    bottom: -1px;
    border-bottom: 1px solid $grey;
  }

  @media (max-width: 880px) {
    border-bottom: 0;
    padding: 16px 0 32px;
    &::after {
      display: none;
    }
  }
}

.section4-text {
  border-bottom: 1px solid $grey;
  align-items: center;
  display: flex;
  position: relative;
  &::after {
    content: "";
    display: block;
    width: 32px;
    height: 1px;
    position: absolute;
    right: -32px;
    bottom: -1px;
    border-bottom: 1px solid $grey;
  }

  @media (max-width: 880px) {
    border-bottom: 0;
    &::after {
      display: none;
    }
  }
}

.section5 {
  background-color: $white;
  padding: 128px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    padding: 32px 16px;
  }
}

.last-section-text {
  padding: 0 256px 128px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    padding: 0 32px 64px;
  }
}

.grid {
  display: grid;
  position: relative;
  width: 100%;
  column-gap: 32px;
  grid-template-columns: 1fr 150px 150px 150px;
  overflow: hidden;

  @media (max-width: 880px), (orientation: landscape) and (max-width: 1023px) {
    grid-template-columns: 1fr 1fr 1fr;

    & > p {
      grid-column: span 3;
      padding-bottom: 16px;
      border-bottom: 1px solid $black;
    }

    & > .column {
      border-bottom: 1px solid $black;
      padding-bottom: 10px;
    }
  }
}

.sticky {
  background-color: #f2f4f5;
  padding-top: 16px;
  transition: all 0.3s linear;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    top: 64px;
    position: sticky;
    z-index: 3;
  }
}

.grid2 {
  display: grid;
  width: 100%;
  column-gap: 32px;
  grid-template-columns: 1fr 150px;
  overflow: hidden;

  @media (max-width: 880px), (orientation: landscape) and (max-width: 1023px) {
    grid-template-columns: 1fr 1fr 1fr;

    & > p {
      grid-column: span 3;
      padding-bottom: 16px;
      border-bottom: 1px solid $black;
    }

    & > .column {
      border-bottom: 1px solid $black;
      padding-bottom: 10px;
    }
  }
}

.small-title {
  display: flex;
  align-items: flex-end;
  padding-bottom: 3px;
  padding-left: 4px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 422px !important;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    width: 261px !important;
  }
}

.mySwiper {
  padding: 0 64px !important;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    padding: 0 32px !important;
  }
}

// scrolls

#Features {
  scroll-margin-top: 224px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    scroll-margin-top: 160px;
  }
}

#Price {
  scroll-margin-top: 160px;
  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    scroll-margin-top: 126px;
  }
}

#Download {
  scroll-margin-top: 160px;
  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    scroll-margin-top: 126px;
  }
}

#Team {
  scroll-margin-top: 224px;
  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    scroll-margin-top: 160px;
  }
}

#History {
  scroll-margin-top: 224px;
  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    scroll-margin-top: 160px;
  }
}
