.regular14-text {
  font-size: 14px;
  line-height: 17px;
}

.regular16-text {
  font-size: 16px;
  line-height: 19px;
}

.regular18-text {
  font-size: 18px;
  line-height: 22px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 11px;
    line-height: 13px;
  }
}

.regular20-text {
  font-size: 20px;
  line-height: 24px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 13px;
    line-height: 15px;
  }
}

.regular24-text {
  font-size: 24px;
  line-height: 28px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 16px;
    line-height: 19px;
  }
}

.regular28-text {
  font-size: 28px;
  line-height: 33px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 19px;
    line-height: 22px;
  }
}

.regular32-text {
  font-size: 32px;
  line-height: 38px;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 19px;
    line-height: 23px;
  }
}

.medium16-text {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 13px;
    line-height: 15px;
  }
}

.medium18-text {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.medium24-text {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 15px;
    line-height: 18px;
  }
}

.medium26-text {
  font-size: 26px;
  line-height: 31px;
  font-weight: 500;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 14px;
    line-height: 17px;
  }
}

.medium32-text {
  font-size: 32px;
  line-height: 38px;
  font-weight: 500;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 19px;
    line-height: 23px;
  }
}

.medium38-text {
  font-size: 38px;
  line-height: 46px;
  font-weight: 500;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.medium46-text {
  font-size: 46px;
  line-height: 55px;
  font-weight: 500;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 18px;
    line-height: 22px;
  }
}

.medium50-text {
  font-size: 50px;
  line-height: 60px;
  font-weight: 500;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 25px;
    line-height: 30px;
  }
}

.medium70-text {
  font-size: 70px;
  line-height: 84px;
  font-weight: 500;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 32px;
    line-height: 38px;
  }
}

.medium90-text {
  font-size: 90px;
  line-height: 108px;
  font-weight: 500;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    font-size: 45px;
    line-height: 54px;
  }
}

.bold19-text {
  font-size: 19px;
  line-height: 23px;
  font-weight: bold;
}

.text-align-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}
