$main: #00c6be;
$black: #000;
$white: #fff;
$grey: rgba(0, 0, 0, 0.46);
$whiteGrey: rgba(255, 255, 255, 0.46);
$backgroundGrey: #f1f4f5;

$colors: (
  main: $main,
  black: $black,
  white: $white,
  grey: $grey,
  whiteGrey: $whiteGrey,
);


@each $key, $color in $colors {
  .color-#{$key} {
    color: $color;
  }

  .bg-#{$key} {
    background-color: $color;
  }
}