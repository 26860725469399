.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.row-column {
  display: flex;
  flex-direction: row;

  @media (max-width: 640px), (orientation: landscape) and (max-width: 1023px) {
    flex-direction: column;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex1 {
  display: flex;
  flex: 1;
}

.column {
  display: flex;
  flex-direction: column;
}

.ai-center {
  align-items: center;
}

.ai-flex-end {
  align-items: flex-end;
}

.jc-center {
  justify-content: center;
}

.jc-space-between {
  justify-content: space-between;
}

.jc-space-around {
  justify-content: space-around;
}

.jc-flex-end {
  justify-content: flex-end;
}

.button {
  padding: 16px 32px;
  border-radius: 30px;
  outline: none;
  border: 0;
  cursor: pointer;
}

.relative {
  position: relative;
}
