@import "colors";
@import "texts";
@import "views";
@import "margins";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  font-family: "FiraGO", sans-serif;
  font-weight: 400;
  color: $black;
  background-color: $backgroundGrey;
  min-width: 360px;
}

button,
input {
  font-family: "FiraGO", sans-serif;
  font-weight: 400;
  color: $black;
}

.br-30 {
  border-radius: 30px;
}

::-webkit-scrollbar {
  background: transparent;
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background: $grey;
}
::-webkit-scrollbar-track {
  background: transparent;
}
