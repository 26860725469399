@import "../../assets/styles/colors";

.other-page-content {
  margin-top: 64px;

  .first-section {
    background-color: $black;
    padding: 128px;
    display: flex;
    align-items: center;
    justify-self: center;
    width: 100%;

    @media (max-width: 1024px) {
      padding: 64px;
    }
  }

  .second-section {
    background-color: $white;
    padding: 64px 128px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1024px) {
      padding: 64px;

      & .row {
        flex-direction: column;
      }
    }

    @media (max-width: 640px),
      (orientation: landscape) and (max-width: 1023px) {
      padding: 32px;
    }
  }

  .border-bottom {
    border-bottom: 1px solid $grey;
    padding-bottom: 32px;
  }

  .mw400 {
    max-width: 400px;
  }

  .display-grid {
    display: grid;
    grid-template-columns: 61% 35%;
    gap: 32px;
  }

  .pl-32 {
    padding-left: 32px;
  }

  @media (min-width: 1200px) {
    .display-grid {
      grid-template-columns: 66% 31%;
    }
  }

  @media (min-width: 1500px) {
    .display-grid {
      grid-template-columns: 69% 31%;
    }
  }

  @media (max-width: 800px) {
    .display-grid {
      display: flex;
      flex-direction: column;
    }

    .pl-32 {
      padding-left: 0px;
    }
  }

  .section2-wrapper {
    border-radius: 30px;
    border: 0.5px solid rgba(0, 0, 0, 0.46);
    padding: 32px;
  }

  .third-section {
    padding: 128px 64px;
    display: flex;
    flex-direction: column;
    background-color: transparent;

    @media (max-width: 640px),
      (orientation: landscape) and (max-width: 1023px) {
      padding: 64px 32px;
    }
  }

  .section3-text-wrapper {
    border-radius: 15px;
    background: #00c6be;
    overflow: hidden;
  }
}
